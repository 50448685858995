import { render, staticRenderFns } from "./background-image.vue?vue&type=template&id=51f13780&scoped=true"
import script from "./background-image.vue?vue&type=script&lang=ts&setup=true"
export * from "./background-image.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./background-image.vue?vue&type=style&index=0&id=51f13780&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51f13780",
  null
  
)

export default component.exports