import { request as axios } from "@/util/request";

const API = {
  get_background: '/boss/operation/background',
}


// 背景图数据接口
export interface BackgroundImageData {
  create_time: number;
  id: number;
  image_url: string;
  type: number;
  update_time: number;
}
// 初始化背景图数据
export function initBackgroundData(remote?: BackgroundImageData[]): BackgroundImageData[] {
  remote = remote || [];
  return remote;
}
// 获取背景图信息
export async function getBackgroundImage() {
  return await axios.get(API.get_background);
}


// 更改背景图设置
export async function changeBackground(add: any, edit: any) {
  return await axios.post(API.get_background, {
    add, edit
  });
}